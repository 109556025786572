/* eslint-disable import/first */
import React from "react"
import { graphql, Link } from "gatsby"
import { LocaleContext } from "../../components/layout"
import SEO from "../../components/seo"

function MapPage({ data: { prismicMapCategory, allPrismicMapCategory } }) {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }

  var mapCategories = {}
  if (allPrismicMapCategory) {
    mapCategories = allPrismicMapCategory.edges.filter(
      edge => edge.node.lang === lang.locale
    )
  }
  return (
    <>
      <SEO title="Maps" keywords={[`navigo`, `garmin`, `maps`]} />

      <div className="container subcategory-page-container">
        <div className="row">
          <div className="col-sm-4 d-none d-md-block">
            <table className="category-table">
              {mapCategories.map(x => (
                <tr className="item">
                  <Link to={x.node.uid}>
                    {x.node.data.map_category_name.text}
                  </Link>
                </tr>
              ))}
            </table>
          </div>

          <div className="col-xs-12 col-sm-8 text-center">
            {prismicMapCategory.data.products.length > 0 && prismicMapCategory.data.products.map(y =>
              y.product &&
                y.product.document.map(product => (
                  <div className="col-xs-12 col-sm-6">        
                    <div className="product-box">
                      <div className="product-image">
                        <img
                          className="img-responsive"
                          src={product.data.thumbnail_image.url}
                        />
                      </div>
                      <div className="product-name">
                        {product.data.product_name}
                      </div>
                      <div className="product-price m-b-10 m-t-10">
                          {product.data.product_price}
                        </div>
                      <a href={product.uid}>
                        <div className="product-details">{i18n.details}</div>
                      </a>
                    </div>
                  </div>
                ))
              )}
               {prismicMapCategory.data.products.length <= 0 &&
                <div>
                  <div
                    id="automotive"
                    class="span16 ui-state section-margin boxable"
                  >
                    <h3>
                      Ulice se mijenjaju. Tvrtke počinju i prestaju postojati.
                    </h3>
                    <p>
                      Kako biste osigurali brzu i točnu navigaciju, provjerite
                      imate li najnovije detaljne karte ulica i točaka interesa.
                      Bez obzira imate li lifetime nadogradnje karata, želite li
                      kupiti nadogradnju ili niste sigurni – započnite tako da
                      preuzmete aplikaciju Garmin Express.
                    </p>
                    <p>
                      <a
                        data-ua-event="Map Updates,Automotive,Download Garmin Express"
                        className="garmin-express-btn"
                        href="http://software.garmin.com/hr-HR/express.html"
                      >
                        Preuzmite Garmin Express
                      </a>
                    </p>
                    <p />                    
                    <hr class="clear top bottom" />
                    <p class="">
                      <div class="span5">
                        <h5>Upozorenja na kamere za mjerenje brzine</h5>
                        <p>
                          Preuzmite najnoviju bazu podataka kamera za svoj
                          Garmin uređaj.
                          <br />
                          <a
                            data-ua-event="Map Updates,Automotive,Speed Camera Alerts"
                            href="http://www.garmin.com/uk/extras-camera-alerts"
                          >
                            Saznajte više
                          </a>
                        </p>
                      </div>
                    </p>
                    <hr class="clear bottom" />
                    <p>
                      <strong>
                        U upravljačku ploču vašeg automobila ugrađen je sustav
                        za navigaciju?
                      </strong>{" "}
                      Pronađite nadogradnje za svoj{" "}
                      <a
                        data-ua-event="Map Updates,Automotive,OEM update"
                        href="https://auto-oem.garmin.com/landing/"
                      >
                        ugrađeni sustav
                      </a>
                      .
                    </p>
                  </div>
                </div>              
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default MapPage

export const pageQuery = graphql`
  query MapCategoryBySlug($uid: String!, $locale: String!) {
    prismicMapCategory(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        map_category_name {
          text
        }
        products {
          product {
            document {
              uid
              data {
                product_name
                product_price
                thumbnail_image {
                  url
                }
              }
            }
          }
        }
      }
    }
    allPrismicMapCategory {
      edges {
        node {
          lang
          uid
          data {
            map_category_name {
              text
            }
          }
        }
      }
    }
  }
`
